<template>
  <Teleport to="body">
    <div class="canvas-container" v-if="shouldRender">
      <CablesPatch
        v-if="projects.length && showPatch"
        ref="cablesPatch"
        :patchDir="'/patch_blink/'"
        :projectsData="mappedData"
        @patch-loaded="handlePatchLoaded"
      />
    </div>
  </Teleport>
 </template>
 
 <script>
 import CablesPatch from "@/components/CablesPatch.vue";
 import useProjectData from "@/mixins/useProjectData";
 import loadingMixin from "@/mixins/loadingMixin";
 import { formatProjectsData } from "@/utils/utils";
 
 export default {
  name: "CablesGLComponent",
  components: {
    CablesPatch
  },
  mixins: [useProjectData, loadingMixin],
  
  data() {
    return {
      showPatch: true,
      currentStream: null,
      shouldRender: false
    };
  },
 
  beforeRouteLeave(to, from, next) {
  // Stop video streams
  if (navigator.mediaDevices) {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        stream.getTracks().forEach(track => track.stop());
      })
      .catch(console.error);
  }
  
  // Stop any existing streams
  if (this.currentStream) {
    this.currentStream.getTracks().forEach(track => track.stop());
    this.currentStream = null;
  }

  this.cleanup();
  next();
},
  mounted() {
    this.shouldRender = true;
  },
 
  beforeRouteLeave(to, from, next) {
    this.cleanup();
    next();
  },
 
  beforeUnmount() {
    this.shouldRender = false;
    this.cleanup();
  },
 
  methods: {
    stopAllVideoStreams() {
    // Stop existing stream
    if (this.currentStream) {
      this.currentStream.getTracks().forEach(track => track.stop());
      this.currentStream = null;
    }

    // Stop any other active video streams
    navigator.mediaDevices?.enumerateDevices()
      .then(devices => {
        devices.forEach(device => {
          if (device.kind === 'videoinput') {
            navigator.mediaDevices.getUserMedia({ video: { deviceId: device.deviceId } })
              .then(stream => {
                stream.getTracks().forEach(track => track.stop());
              })
              .catch(() => {});
          }
        });
      })
      .catch(console.error);
  },

  cleanup() {
    this.stopAllVideoStreams();
    if (window.CABLES?.patch) {
      try {
        window.CABLES.patch.pause();
        window.CABLES.patch.onDelete?.();
        window.CABLES.patch.dispose();
        window.CABLES.patch = null;

        const canvas = document.getElementById('glcanvas');
        if (canvas) {
          const gl = canvas.getContext('webgl2');
          if (gl) {
            gl.getExtension('WEBGL_lose_context')?.loseContext();
          }
        }
      } catch (e) {
        console.warn('Error cleaning up CABLES patch:', e);
      }
    }
    this.showPatch = false;
  },
 
  beforeRouteLeave(to, from, next) {
  this.stopAllVideoStreams();
  this.cleanup();
  next();
},

beforeUnmount() {
  this.stopAllVideoStreams();
  this.cleanup();
},
// Blinking.vue - update handleSelectedIdsUpdated method
async handleSelectedIdsUpdated(stringOfIds) {
  try {
    if (!stringOfIds) return;
    
    const arrayOfIds = stringOfIds.split(",").filter(Boolean);
    if (arrayOfIds.length >= 3) {
      // Stop media and cleanup
      if (this.currentStream) {
        this.currentStream.getTracks().forEach(track => track.stop());
        this.currentStream = null;
      }
      
      if (this.$refs.cablesPatch) {
        await this.$refs.cablesPatch.cleanup();
      }
      
      // Update store
      await this.$store.commit("selectProjects", arrayOfIds.slice(0, 3));
      this.showPatch = false;
      await this.$nextTick();

      // Use named route
      await this.$router.push({ name: 'selectedProjects' });
    }
  } catch (err) {
    console.error('Error handling IDs:', err);
  }
},
 
    handlePatchLoaded() {
      const cabelsData = window?.CABLES?.patch?.getVar("selected_project_ids");
      if (cabelsData) {
        cabelsData.on("change", this.handleSelectedIdsUpdated);
      }
    }
  },
 
  computed: {
    mappedData() {
      return {
        items: formatProjectsData(this.projects, 11)
      };
    }
  }
 };
 </script>
 
 <style>
 .canvas-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
 }
 
 canvas {
  width: 100% !important;
  height: 100% !important;
  background: transparent !important;
 }
 
 #videoInput {
  position: fixed;
  left: -100%;
  visibility: hidden;
 }
 
 @media (max-width: 768px) {
  .canvas-container {
    height: calc(100vh - env(safe-area-inset-bottom, 0px));
  }
  
  canvas {
    height: calc(100vh - env(safe-area-inset-bottom, 0px)) !important;
  }
 }
 </style>