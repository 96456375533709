<template>
  <div class="slider-container" :class="{'fade-in': isLoaded, 'hidden': !isLoaded}">
    <swiper-container
      v-if="safeImages.length"
      class="swiper"
      ref="swiperEl"
      :slides-per-view="3"
      :space-between="10"
      :loop="true"
      :mousewheel="true"

      :grid="{
        rows: 2,
      }"
      :breakpoints="{
        320: {
          slidesPerView: 1,
          direction: 'vertical',
          spaceBetween: 30,
          loop: false,
          grid: {
            rows: 1,
          },
        },
        600: { slidesPerView: 2, spaceBetween: 10 },
        900: { slidesPerView: 2, spaceBetween: 10 },
        1200: { slidesPerView: 3, spaceBetween: 10 },
      }"
    >
      <swiper-slide
        v-for="(media, index) in safeImages"
        :key="getSlideKey(media, index)"
        class="swiper-slide"
      >
        <template v-if="isAboutLink(media)">
          <div @click="toggleDescription(media.description)" class="about-card">
            <h3>ABOUT {{ media.name }}</h3>
            <svg
              class="arrow-icon"
              width="50"
              height="30"
              viewBox="0 0 50 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 15H42M42 15L29 2M42 15L29 28"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </template>
        <template v-else>
          <img
            v-if="isImage(media)"
            :src="media.url"
            alt="Project Image"
            class="media-item"
            @load="onImageLoad(index)"
          />
          <div v-else-if="isVideo(media)" class="video-container">
            <video
              :src="media.url"
              :ref="el => setVideoRef(el, index)"
              class="media-item"
              autoplay
              loop
              playsinline
              muted
              @loadeddata="checkForAudioTrack(index)"
            >
              Your browser does not support the video tag.
            </video>
            <button 
              v-if="hasAudioTrack(index)" 
              @click="toggleSound(index)" 
              class="sound-toggle"
            >
              <img 
                :src="getSoundIcon(index)" 
                class="sound-icon"
              >
            </button>
          </div>
        </template>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onBeforeUnmount, computed, reactive, toRefs } from 'vue';
import { register } from 'swiper/element/bundle';

register();

export default defineComponent({
  name: 'Slider',
  props: {
    images: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  setup(props) {
    const state = reactive({
      isLoaded: false,
      swiperEl: null,
      videoRefs: {},
      loaded: {},
      audioTracks: {},
      mutedStates: {}
    });

    const safeImages = computed(() => {
      return Array.isArray(props.images) ? props.images : [];
    });

    const setVideoRef = (el, index) => {
      if (el) {
        state.videoRefs[index] = el;
      }
    };

    onMounted(() => {
      state.isLoaded = true;
    });

    onBeforeUnmount(() => {
      Object.values(state.videoRefs).forEach(video => {
        if (video) {
          video.pause();
          video.src = '';
          video.load();
        }
      });
    });

    return {
      ...toRefs(state),
      safeImages,
      setVideoRef
    };
  },

  data() {
    return {
      soundOnIcon: '/DLW-Sound-On.svg',
      soundOffIcon: '/DLW-Sound-Off.svg',
      buttonImageStates: {}
    }
  },

  methods: {
    getSlideKey(media, index) {
      return `slide-${media?.url || index}-${index}`;
    },

    isAboutLink(media) {
      return media?.type === "link";
    },

    isImage(media) {
      return media?.mimeType?.startsWith("image/");
    },

    isVideo(media) {
      return media?.mimeType?.startsWith("video/");
    },

    toggleDescription(description) {
      if (description) {
        this.$emit("toggle-description", description);
      }
    },

    async checkForAudioTrack(index) {
      const video = this.videoRefs[index];
      if (!video) return;

      await new Promise(resolve => setTimeout(resolve, 100));

      const hasAudio = video.mozHasAudio ||
        Boolean(video.webkitAudioDecodedByteCount) ||
        Boolean(video.audioTracks?.length) ||
        (video.webkitAudioDecodedByteCount !== undefined && video.webkitAudioDecodedByteCount > 0);

      this.audioTracks[index] = hasAudio;
      if (hasAudio) {
        this.mutedStates[index] = true;
      }
    },

    hasAudioTrack(index) {
      return this.audioTracks[index] === true;
    },

    getSoundIcon(index) {
      return this.mutedStates[index] ? this.soundOffIcon : this.soundOnIcon;
    },

    toggleSound(index) {
    const video = this.videoRefs[index];
    if (!video) return;

    // If unmuting this video, mute all others first
    if (this.mutedStates[index]) {
      // Mute all videos except the current one
      Object.entries(this.videoRefs).forEach(([videoIndex, videoRef]) => {
        if (videoRef && videoIndex !== index.toString()) {
          videoRef.muted = true;
          this.mutedStates[videoIndex] = true;
        }
      });
    }

    // Toggle the current video's mute state
    this.mutedStates[index] = !this.mutedStates[index];
    video.muted = this.mutedStates[index];
  },

  // Keep your other methods as they are
  muteAllVideos() {
    Object.entries(this.videoRefs).forEach(([index, video]) => {
      if (video) {
        video.muted = true;
        this.mutedStates[index] = true;
      }
    });
  },

    muteAllVideos() {
      Object.entries(this.videoRefs).forEach(([index, video]) => {
        if (video) {
          video.muted = true;
          this.mutedStates[index] = true;
        }
      });
    },

    onImageLoad(index) {
      this.loaded[index] = true;
      this.$emit('image-loaded', index);
    }
  }
});
</script>


<style scoped>
.swiper-container {
 height: 100dvh;
}

.swiper {
 width: 100vw;
 height: 94vh;
 margin: auto 0;
}

.swiper-slide {
 text-align: center;
 font-size: 18px;
 display: flex;
 justify-content: center;
}

.media-item {
 display: block;
 max-height: 100%;
 width: 100%;
 object-fit: contain;
}

.video-container {
 position: relative;
 display: flex;
 justify-content: center;
 align-items: flex-end;
}

.sound-toggle {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 50;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background: #d9d9d91a;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  /* Add these properties */
  display: flex;
  justify-content: center;
  align-items: center;
}

.sound-toggle img {
  width: 15px;
  height: 15px;
  mix-blend-mode: difference;
  opacity: 0.5;
  /* Remove margin */
}

.about-card {
 text-align: center;
 font-size: 50px;
 max-width: 100%;
 padding: 10vw;
 line-height: 1;
 font-family: 'Kommuna Demo', sans-serif;
}

@media (max-width: 600px) {
 .swiper-slide {
   margin-left: 0 !important;
 }

 .swiper-container {
   height: 90dvh;
   width: 90vw;
 }

 .swiper {
   width: 90vw;
   max-height: 90vh;
   margin: auto;
 }

 .swiper-slide {
   overflow-x: visible;
   overflow-y: visible;
   text-align: center;
   font-size: 18px;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .media-item {
   display: block;
   width: 100%;
   height: 100%;
   object-fit: contain;
   margin-left: 0 !important;
   opacity: 1;
 }

 .video-container {
   width: 100%;
   max-height: 80dvh;
 }

 .sound-toggle {
   bottom: 80px;
 }

 .sound-toggle img {
   width: 14px;
   height: 14px;
   margin-left: -7px;
 }

 .about-card {
   font-size: clamp(30px, 5vw, 50px);
 }

 .arrow-icon {
   transform: rotate(90deg);
 }
}

@media (hover: none) or (pointer: coarse) {
 .button:hover {
   background-color: initial;
 }
}

.fade-in {
 animation: fadeIn 1s ease-in forwards;
}

.hidden {
 opacity: 0;
}

@keyframes fadeIn {
 from { opacity: 0; }
 to { opacity: 1; }
}
</style>