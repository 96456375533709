<template>
  <div class="project" :key="project?.id">
    <router-link
      v-if="project"
      :to="{
        name: 'ProjectDetail',
        params: { projectSlug: project.projectSlug },
      }"
    >
      <div class="project-wrapper" :class="{ 'loading': !isMediaLoaded }">
        <div class="media-wrapper">
          <div v-if="project.heroImage">
            <img
              v-if="isImage(project.heroImage)"
              :src="project.heroImage.url"
              :alt="project.projectName"
              class="media-item"
              @load="handleMediaLoad"
            />
            <video
              v-else-if="project.heroImage.url"
              :src="project.heroImage.url"
              class="media-item"
              autoplay
              muted
              loop
              playsinline
              @loadeddata="handleMediaLoad"
            >
              Your browser does not support the video tag.
            </video>
          </div>

          <div class="project-name">{{ project.projectName }}</div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Project',
  props: {
    project: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        projectName: '',
        projectSlug: '',
        heroImage: {
          url: '',
          mimeType: ''
        }
      })
    },
  },
  setup() {
    const isMediaLoaded = ref(false);

    return {
      isMediaLoaded
    };
  },
  methods: {
    handleMediaLoad() {
      this.isMediaLoaded = true;
    },
    isImage(media) {
      if (!media || !media.mimeType) return false;
      return media.mimeType.startsWith("image/");
    },
    handleBeforeEnter(el) {
      el.style.opacity = '0';
    },
    handleEnter(el, done) {
      setTimeout(() => {
        el.style.transition = 'opacity 0.5s ease';
        el.style.opacity = '1';
        done();
      }, 0);
    },
    handleLeave(el, done) {
      el.style.transition = 'opacity 0.5s ease';
      el.style.opacity = '0';
      setTimeout(done, 500);
    }
  },
  mounted() {
    if (!this.project) {
      console.warn('Project data is undefined');
    }
  }
});
</script>

<style scoped>
.project-wrapper {
  position: relative;
  overflow: hidden;
  transition: opacity 0.3s ease;
}

.project-wrapper.loading {
  opacity: 0;
}

.project-wrapper:hover img,
.project-wrapper:hover video {
  filter: blur(20px);
  transition: filter 0.3s ease;
}

.media-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.media-item {
  width: 100%;
  height: auto;
  display: block;
  transition: filter 0.3s ease;
}

.project-name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  padding: 10px;
  font-size: clamp(22px, 3vw, 40px);
  pointer-events: none;
  transition: opacity 0.3s ease;
  mix-blend-mode: exclusion;
  opacity: 1;
  text-align: center;
  line-height: 1;
  z-index: 2;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-enter-active {
  animation: fadeIn 0.5s ease-in-out;
}

.fade-leave-active {
  animation: fadeIn 0.5s ease-in-out reverse;
}

@media (hover: none) or (pointer: coarse) {
  .project-wrapper:hover img,
  .project-wrapper:hover video {
    filter: initial;
  }
  
  .project-name {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .project-name {
    font-size: clamp(18px, 2.5vw, 30px);
  }
}

.loading-placeholder {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>