import { createStore } from 'vuex';
import { fetchProjects } from "@/api/api";

export const store = createStore({
 state: {
   loadedProjects: [],
   selectedProjects: [],
   currentRoute: null,
   loading: false,
   error: null
 },

 mutations: {
   setProjects(state, projects) {
     state.loadedProjects = Array.isArray(projects) ? projects : [];
   },

   selectProjects(state, projectIds) {
     if (!Array.isArray(projectIds)) {
       console.error('projectIds must be an array');
       return;
     }
     try {
       state.selectedProjects = state.loadedProjects.filter(p => 
         projectIds.includes(p.id?.toString()) || projectIds.includes(p.id)
       );
     } catch (err) {
       console.error('Error in selectProjects mutation:', err);
       state.selectedProjects = [];
     }
   },

   setCurrentRoute(state, route) {
     state.currentRoute = route;
   },

   setLoading(state, loading) {
     state.loading = loading;
   },

   setError(state, error) {
     state.error = error;
   },

   clearSelectedProjects(state) {
     state.selectedProjects = [];
   }
 },

 actions: {
   async loadProjects({ commit }, forceReload = false) {
     try {
       commit('setLoading', true);
       commit('setError', null);
       
       const projects = await fetchProjects();
       if (Array.isArray(projects)) {
         commit('setProjects', projects);
       } else {
         throw new Error('Invalid projects data received');
       }
     } catch (error) {
       console.error('Error loading projects:', error);
       commit('setError', error.message);
       commit('setProjects', []);
     } finally {
       commit('setLoading', false);
     }
   },

   updateCurrentRoute({ commit }, route) {
     commit('setCurrentRoute', route);
   },

   resetSelection({ commit }) {
     commit('clearSelectedProjects');
   }
 },

 getters: {
   projectsCount: state => state.loadedProjects.length,
   getCurrentRoute: state => state.currentRoute,
   isLoading: state => state.loading,
   hasError: state => state.error !== null,
   getError: state => state.error,
   getSelectedProjects: state => state.selectedProjects,
   hasSelectedProjects: state => state.selectedProjects.length > 0
 }
});