```vue
<template>
  <div class="canvas-container">
    <canvas v-bind:id="canvasId" tabIndex="1"></canvas>
  </div>
</template>

<script>
import logger from '@/utils/logger';

export default {
  props: {
    patchDir: String,
    canvasId: {
      type: String,
      default: 'glcanvas'
    },
    patchOptions: Object,
    projectsData: Object
  },
  
  mounted() {
  this.initializePatch();
  window.addEventListener('beforeunload', this.cleanup);
},

beforeDestroy() {
  window.removeEventListener('beforeunload', this.cleanup);
},
  
  methods: {
    async initializePatch() {
      const script = document.createElement('script');
      script.src = `${this.patchDir}/js/patch.js`;
      script.async = true;
      
      script.onload = () => {
        const canvas = document.getElementById(this.canvasId);
        const gl = canvas.getContext('webgl2', {
          alpha: true,
          antialias: true,
          depth: true,
          failIfMajorPerformanceCaveat: false,
          powerPreference: 'default',
          premultipliedAlpha: false,
          preserveDrawingBuffer: true,
          stencil: true
        });

        if (!gl) {
          console.error('WebGL not available');
          return;
        }

        const options = {
          patch: window.CABLES.exportedPatch,
          prefixAssetPath: this.patchDir,
          jsPath: this.patchDir + '/js/',
          glCanvasId: this.canvasId,
          glCanvasResizeToWindow: false,
          canvas: {
            gl: gl,
            alpha: true,
            premultipliedAlpha: false,
            preserveDrawingBuffer: true,
            antialias: true
          },
          onFinishedLoading: () => {
            logger.log(this.patchDir + ' finished loading');
            this.$emit('patch-loaded');
          },
          variables: {
            inputJson: this.projectsData,
            HiresDisplay: window.devicePixelRatio || 1,
            showUI: 0
          },
          ...this.patchOptions
        };

        window.CABLES.patch = new window.CABLES.Patch(options);
      };
      
      document.body.appendChild(script);
    },

    async cleanup() {
  try {
    // Pause animations first
    if (window.CABLES?.patch) {
      window.CABLES.patch.pause();
      window.cancelAnimationFrame(window.CABLES.patch._animFrameHandle);
      
      // Wait for frame to complete
      await new Promise(resolve => setTimeout(resolve, 50));
      
      // Reset context state
      const canvas = document.getElementById(this.canvasId);
      if (canvas) {
        const gl = canvas.getContext('webgl2');
        if (gl) {
          try {
            gl.flush();
            gl.finish();
            gl.bindFramebuffer(gl.FRAMEBUFFER, null);
            gl.bindRenderbuffer(gl.RENDERBUFFER, null);
            gl.bindBuffer(gl.ARRAY_BUFFER, null);
            gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
            gl.bindTexture(gl.TEXTURE_2D, null);
            gl.useProgram(null);
            gl.disable(gl.DEPTH_TEST);
            gl.disable(gl.BLEND);
            gl.disable(gl.CULL_FACE);
            gl.disable(gl.STENCIL_TEST);
            gl.getExtension('WEBGL_lose_context')?.loseContext();
          } catch (e) {
            // Ignore WebGL errors during cleanup
          }
        }
      }
      
      window.CABLES.patch.dispose();
      window.CABLES.patch = null;
    }

    await this.$nextTick();
  } catch (e) {
    console.warn('Cleanup error:', e);
  }
}
  },
  
  beforeUnmount() {
    this.cleanup();
  }
};
</script>

<style>
.canvas-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
}

canvas {
  width: 100% !important;
  height: 100% !important;
  background: transparent !important;
}

@media (max-width: 768px) {
  .canvas-container {
    height: calc(100vh - env(safe-area-inset-bottom, 0px));
  }
  
  canvas {
    height: calc(100vh - env(safe-area-inset-bottom, 0px)) !important;
  }
}
</style>
```