import "./assets/main.css";
import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from './App.vue';
import router from './router';
import { store } from "./store/store";
import MasonryWall from '@yeger/vue-masonry-wall';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { register } from 'swiper/element/bundle';

// Register Swiper custom elements
register();

const app = createApp(App);

// Use plugins
app.use(store);
app.use(MasonryWall);
app.use(router);

// Router guards
router.beforeEach((to, from, next) => {
    // Handle CABLES patch
    try {
        if (window.CABLES?.patch) {
            window.CABLES.patch.dispose();
            window.CABLES.patch = null;
        }
    } catch (error) {
        console.warn('Error disposing CABLES patch:', error);
    }

    const visited = localStorage.getItem('visited');
    if (!visited && to.name !== 'first') {
        next({ name: 'first' });
        localStorage.setItem('visited', 'true');
    } else {
        next();
    }
});

// Mount the app after store initialization
store.dispatch('loadProjects').finally(() => {
    app.mount('#app');
});

// Global error handler
app.config.errorHandler = (err, vm, info) => {
    console.error('Global error:', err);
    console.error('Component:', vm);
    console.error('Error info:', info);
};